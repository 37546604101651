<template>
  <div class="m-4">
    <h2>About Jukebox Tools</h2>
    <p>
      <em>
        Welcome to Jukebox Tools, where the Golden Age of Music meets Modern
        Innovation.
      </em>
    </p>

    <h3>Our Story:</h3>
    <p>
      Jukebox Tools was born out of a passion for music and nostalgia. Founded
      by a group of avid collectors and tech enthusiasts, we've combined our
      love for the classic jukebox with the latest in digital technology. Our
      mission is to help jukebox owners and music lovers preserve the charm of
      their vintage collections while bringing them into the 21st century.
    </p>

    <h3>What We Do:</h3>
    <p>
      We specialize in providing intuitive, user-friendly solutions for
      organizing and showcasing your jukebox record collections. Our services
      include:
    </p>
    <ul>
      <li>
        <strong>Record Organization:</strong>
        Streamline your collection with our easy-to-use digital cataloging
        system.
      </li>
      <li>
        <strong>Custom Title Strip Creation:</strong>
        Design and print personalized title strips to give your jukebox a unique
        touch.
      </li>
      <li>
        <strong>Jukebox Layout:</strong>
        We provivde a tool to help plan where you want your records to be placed in your jukebox; before you start pulling records.</li>
    </ul>
    <br />
    <h3>Our Promise:</h3>
    <p>
      At Jukebox Tools, we're committed to preserving the legacy of jukebox
      culture. We understand the value of each record and the stories they tell.
      Our tools are designed to honor this heritage, ensuring that your jukebox
      continues to be the centerpiece of your musical enjoyment.
    </p>

    <h3>Join Our Tune:</h3>
    <p>
      Whether you're a seasoned collector or new to the world of jukeboxes, we
      welcome you to join our community. Let's keep the rich history of
      jukeboxes spinning for generations to come.
    </p>

    <p>
      <strong>
        Thank you for choosing Jukebox Tools - Your Partner in Keeping the Music
        Playing!
      </strong>
    </p>
  </div>
</template>

<script>
export default {
  name: "about",
};
</script>
